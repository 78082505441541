.kiosk-components-scope .stepper-component .steps-graph {
width:100%
}

.kiosk-components-scope .stepper-component .step-box {
width:100%;height:5px;border:1px solid var(--counter-border-color);border-radius:100px
}

.kiosk-components-scope .stepper-component .step-box-finished {
background-color:var(--counter-finished-background-color)
}

.kiosk-components-scope .stepper-component .step-box-todo {
background-color:var(--counter-background-color)
}

.kiosk-components-scope .stepper-component .step-counter {
color:var(--counter-font-color)
}

