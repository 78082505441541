.kiosk-components-scope .welcome-header-element {
height:100%
}

.kiosk-components-scope .welcome-header-element.welcome-header-background.with-background {
background-color:var(--header-background-color)
}

.kiosk-components-scope .welcome-header-element.welcome-header-background.transparent-background {
background-color:rgba(0,0,0,0)
}

