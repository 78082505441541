.kiosk-components-scope .large-header-wrapper {
display:flex;height:330px;min-height:330px;padding:60px 93px;overflow:hidden;color:var(--header-font-color);background-color:var(--header-background-color)
}

.kiosk-components-scope .large-header-wrapper.large-header-wrapper-transparent {
background-color:rgba(0,0,0,0)
}

.kiosk-components-scope .large-header-wrapper .large-header-logo {
height:82px
}

.kiosk-components-scope .large-header-wrapper .large-header-background-image {
position:absolute;top:-4px;left:-4px;width:calc(100% + 8px);height:calc(100% + 8px);filter:blur(2px);background-position:center center;background-size:cover;opacity:.7
}

.kiosk-components-scope .large-header-wrapper .large-header-content {
position:relative;z-index:1
}

.kiosk-components-scope .large-header-wrapper .large-header-description.large-header-description-first-line,.kiosk-components-scope .large-header-wrapper .large-header-description.large-header-description-second-line {
font-size:40px;line-height:1.3
}

