.kiosk-components-scope .check-in-id-scanning-wrapper {
display:flex;height:100%;overflow-x:hidden
}

.kiosk-components-scope .check-in-id-scanning-wrapper .check-in-id-scanning-wrapper-with-image {
padding-right:50px
}

.kiosk-components-scope .check-in-id-scanning-wrapper .check-in-id-scanning-section {
flex:1 1
}

.kiosk-components-scope .check-in-id-scanning-wrapper .check-in-id-scanning-image {
width:50%;object-fit:cover
}

