.kiosk-components-scope .check-in-confirmation-form-wrapper {
display:flex;flex-direction:column
}

.kiosk-components-scope .check-in-confirmation-form-wrapper .check-in-confirmation-form-subsection {
flex:1 1
}

.kiosk-components-scope .check-in-confirmation-form-wrapper .check-in-confirmation-form-subsection:first-child:not(:only-child) {
margin-bottom:var(--spacing-xxlg)
}

