.kiosk-components-scope .review-address-modal {
text-align:center
}

.kiosk-components-scope .review-address-modal .review-address-subtitle {
white-space:pre-wrap
}

.kiosk-components-scope .review-address-modal .review-address-content-wrapper {
display:grid;grid-template-rows:repeat(7, auto);grid-template-columns:repeat(2, 1fr);grid-auto-flow:column;grid-gap:var(--spacing-md) var(--spacing-xxlg);gap:var(--spacing-md) var(--spacing-xxlg);text-align:left
}

.kiosk-components-scope .review-address-modal .buttons-wrapper {
display:flex;justify-content:space-between
}

