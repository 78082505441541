.kiosk-components-scope .scanning-document-error-modal {
text-align:center
}

.kiosk-components-scope .scanning-document-error-modal .error-circular-icon {
color:var(--primary-color)
}

.kiosk-components-scope .scanning-document-error-modal .buttons-wrapper {
display:flex;justify-content:space-between
}

.kiosk-components-scope .scanning-document-error-modal .buttons-wrapper>:only-child {
margin:auto
}

