.kiosk-components-scope .qrscanner-modal-container .qrscanner-scanner-preview-container {
display:flex;width:240px;height:240px;min-height:240px;margin:auto
}

.kiosk-components-scope .qrscanner-modal-container .qrscanner-scanner-preview {
width:100%;height:100%
}

.kiosk-components-scope .qrscanner-modal-container .qrscanner-scanner-loader {
width:100%;margin:auto
}

.kiosk-components-scope .qrscanner-modal-container .hidden {
display:none
}

