.kiosk-components-scope .terms-and-conditions-component .terms-and-conditions-title {
margin-bottom:var(--spacing-sm)
}

.kiosk-components-scope .terms-and-conditions-component .terms-and-conditions-consents {
margin-top:var(--spacing-lg);margin-left:-10px
}

.kiosk-components-scope .terms-and-conditions-component .terms-and-conditions-consents .terms-and-conditions-link {
text-decoration:underline
}

