.kiosk-components-scope .check-name-details-modal {
text-align:center
}

.kiosk-components-scope .check-name-details-modal .content-wrapper {
display:grid;grid-template-columns:1fr 1fr;grid-auto-rows:auto;grid-gap:var(--spacing-sm);gap:var(--spacing-sm);align-items:baseline
}

.kiosk-components-scope .check-name-details-modal .content-label {
text-align:end
}

.kiosk-components-scope .check-name-details-modal .content-value {
text-align:start
}

.kiosk-components-scope .check-name-details-modal .buttons-wrapper {
display:flex
}

