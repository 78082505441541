.kiosk-components-scope .address-form {
display:grid;grid-template-columns:repeat(10, 1fr);grid-gap:var(--spacing-xxs) var(--spacing-lg);gap:var(--spacing-xxs) var(--spacing-lg)
}

.kiosk-components-scope .address-form .address-header {
grid-area:1/span 10
}

.kiosk-components-scope .address-form .address-addressline {
grid-area:2/span 5
}

.kiosk-components-scope .address-form .address-code {
grid-area:3/span 2
}

.kiosk-components-scope .address-form .address-city {
grid-area:3/span 3
}

.kiosk-components-scope .address-form .address-country {
grid-area:3/span 5
}

.kiosk-components-scope .address-form .address-state {
grid-area:4/span 5
}

.kiosk-components-scope .address-form .address-district {
grid-area:4/span 5
}

