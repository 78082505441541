.kiosk-components-scope .scanned-data-verification-modal {
text-align:center
}

.kiosk-components-scope .scanned-data-verification-modal .data-wrapper {
display:flex;gap:var(--spacing-md);overflow-y:auto;text-align:left
}

.kiosk-components-scope .scanned-data-verification-modal .data-column {
display:grid;flex:1 1;grid-gap:var(--spacing-md);gap:var(--spacing-md);height:max-content
}

.kiosk-components-scope .scanned-data-verification-modal .buttons-wrapper {
display:flex;justify-content:space-between
}

