.kiosk-components-scope .footer-container {
position:relative;z-index:999
}

.kiosk-components-scope .footer-container.footer-container-transparent {
background-color:rgba(0,0,0,0)
}

.kiosk-components-scope .footer-container .footer-stepper {
position:absolute;top:0;left:0;width:100%;transform:translateY(-100%)
}

