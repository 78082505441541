.kiosk-components-scope .folio-style-type-selection-wrapper .folio-style-type-selection-header {
white-space:pre-wrap
}

.kiosk-components-scope .folio-style-type-selection-wrapper .folio-style-type-selection-options {
display:flex;flex-wrap:wrap;gap:var(--spacing-md);margin-top:var(--spacing-md);margin-left:-10px
}

.kiosk-components-scope .folio-style-type-selection-wrapper .folio-style-type-selection-options.folio-style-type-selection-options-column {
flex-direction:column
}

