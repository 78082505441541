.kiosk-components-scope .checkout-charges-tax-id-section {
line-height:1.5;text-align:right
}

.kiosk-components-scope .checkout-charges-tax-id-section .checkout-charges-tax-id-section-link {
color:var(--primary-color)
}

.kiosk-components-scope .checkout-charges-tax-id-modal {
display:flex;flex-direction:column;align-items:center
}

.kiosk-components-scope .checkout-charges-tax-id-modal .checkout-charges-tax-id-modal-buttons-wrapper {
display:flex;justify-content:space-between;width:100%
}

