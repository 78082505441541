.kiosk-components-scope .additional-info-form {
display:grid;grid-template-columns:repeat(10, 1fr);grid-gap:var(--spacing-xxs) var(--spacing-lg);gap:var(--spacing-xxs) var(--spacing-lg)
}

.kiosk-components-scope .additional-info-form .additional-info-form-header {
grid-area:1/span 10
}

.kiosk-components-scope .additional-info-form .additional-info-form-nationality {
grid-area:2/span 5
}

