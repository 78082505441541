.kiosk-components-scope .reservation-header-section {
background-color:var(--reservation-header-background-color)
}

.kiosk-components-scope .reservation-header-section.reservation-header-section-with-border {
border-bottom:1px solid var(--gray-color-1)
}

.kiosk-components-scope .reservation-header-section.reservation-header-transparent {
background-color:rgba(0,0,0,0)
}

