.kiosk-components-scope .contact-form {
display:grid;grid-template-columns:repeat(10, 1fr);grid-gap:var(--spacing-xxs) var(--spacing-lg);gap:var(--spacing-xxs) var(--spacing-lg)
}

.kiosk-components-scope .contact-form .contact-form-header {
grid-column:1/10
}

.kiosk-components-scope .contact-form .contact-phone-custom-message {
grid-column:1/6
}

.kiosk-components-scope .contact-form .contact-email-custom-message {
grid-column:6/11
}

.kiosk-components-scope .contact-form .contact-form-prefix {
grid-column:1/3
}

.kiosk-components-scope .contact-form .contact-form-prefix~.contact-form-mobile {
grid-column:3/6
}

.kiosk-components-scope .contact-form .contact-form-mobile {
grid-column:1/6
}

.kiosk-components-scope .contact-form .contact-form-email {
grid-column:6/11
}

