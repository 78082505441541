.kiosk-components-scope .service-modal-magic-button {
z-index:1
}

.kiosk-components-scope .connection-modal-component {
z-index:2147483646
}

.kiosk-components-scope .global-modals-components {
z-index:2147483645
}

.kiosk-components-scope .screen-saver-element {
z-index:2147483647
}

