.kiosk-components-scope .notification-bar-element {
width:100%;height:50px;overflow:hidden;color:var(--white-color)
}

.kiosk-components-scope .notification-bar-element.notification-bar-type-error {
background-color:var(--error-state-color)
}

.kiosk-components-scope .notification-bar-element.notification-bar-type-warn {
background-color:var(--warning-state-color)
}

.kiosk-components-scope .notification-bar-element.notification-bar-type-success {
background-color:var(--success-state-color)
}

.kiosk-components-scope .notification-bar-element .notification-bar-icon {
width:22px;height:22px
}

