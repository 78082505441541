.kiosk-components-scope .route-buttons-wrapper .route-buttons-grid {
justify-content:space-evenly;width:fit-content;max-width:1200px;margin:auto
}

@media(min-width: 1024px) {
.kiosk-components-scope .route-buttons-wrapper .route-buttons-grid {
width:100%
}


}

.kiosk-components-scope .route-buttons-wrapper .route-buttons-grid .route-buttons-description-item {
margin:auto 0
}

@media(min-width: 1024px) {
.kiosk-components-scope .route-buttons-wrapper .route-buttons-grid .route-buttons-description-item {
grid-row:2;margin:0 auto;text-align:center
}


}

